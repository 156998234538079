
import { computed, defineComponent, inject} from "vue";
import { Store } from '@/stores'; // eslint-disable-line
import OrdersNav from '@/components/OrdersNav/OrdersNav.vue';

export default defineComponent({
    components: { OrdersNav },
    setup() {
        const store = inject('store') as Store;
        return {
            navItems: computed(() => store.state.preStations)
        };
    }
});
